import React from 'react';

import { URLS } from '../constants';

const Footer = () => {
  return (
    <>
      <div className="footer">
        <p>
          Having trouble getting through? Don’t worry we’ll help you fix this asap! Please create a
          support ticket on{' '}
          <a href={URLS.SUPPORT_PORTAL} target="_blank" rel="noopener noreferrer">
            Support Portal
          </a>{' '}and describe your challenge. One of our support representatives will get in touch with you immediately.
        </p>
        <div className="footer-line" />
        <div className="footer-wrapper">
          <p className="footer-rights">Powered by Academy © 2020</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
