import React from 'react';

const defaultContextValue = {
  errorMsg: 'test',
  set: () => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class ErrorContextProviderComponent extends React.Component {
  constructor() {
    super();

    this.setErrorMsg = this.setErrorMsg.bind(this);
    this.state = {
      ...defaultContextValue,
      set: this.setErrorMsg,
    };
  }

  setErrorMsg(newData) {
    this.setState({ errorMsg: newData });
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export { Consumer as default, ErrorContextProviderComponent };
