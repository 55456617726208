import React from 'react';
import { Helmet } from 'react-helmet';

import { ErrorContextProviderComponent } from './ErrorContext';
import Menu from './Menu';
import Footer from './Footer';

import LoginPic from '../assets/images/login-picture.png';

// Styles
import '../assets/styles/main.scss';

const Layout = ({ children, title, hideHeader = false, hideFooter = false }) => {
  const seoTitle = title || 'academylogin';

  return (
    <ErrorContextProviderComponent>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="title" content={seoTitle} />
        <meta name="robots" content="index" />
      </Helmet>

      <section className="section">
        <div className="section-wrapper_outer">
          <div className="section-img">
            <img src={LoginPic} alt="" />
            {/* <h1>Your bridge to better</h1> */}
          </div>
          <div className="section-wrapper_inner">
            <div className="section-container">
              <div className="section-container-inner">{!hideHeader && <Menu />}</div>
              <div className="section-container-inner">{children}</div>
              <div className="section-container-inner">{!hideFooter && <Footer />}</div>
            </div>
          </div>
        </div>
      </section>
    </ErrorContextProviderComponent>
  );
};

export default Layout;
