import React from 'react';

import Flag from '../assets/images/flag-uk.svg';

const LanguageUK = () => {
  return (
    <div className="section_lang">
      <img className="section-logo" src={Flag} />
      <span>English</span>
    </div>
  );
};

export default LanguageUK;
