import React from 'react';
import { Link } from 'gatsby';

import ErrorContextConsumer from './ErrorContext';

import Logo from '../assets/images/logo.png';
import LanguageUK from './LanguageUK';

const Menu = () => {
  return (
    <ErrorContextConsumer>
      {({ errorMsg }) => (
        <menu className="section-menu">
          <div className="section-title">
            <Link to="/">
              {/*Academy*/}
            </Link>
          </div>
          <LanguageUK />
        </menu>
      )}
    </ErrorContextConsumer>
  );
};

export default Menu;
